/** @jsxRuntime classic */
/** @jsx h */
import {
  AutocompleteComponents,
  AutocompletePlugin,
  getAlgoliaFacets,
} from '@algolia/autocomplete-js';
import { h } from 'preact';

import { ALGOLIA_PRODUCTS_INDEX_NAME, WEBSITE_URL } from '../constants';
import { searchClient } from '../searchClient';
import { PopularCategoryHit } from '../types';

const baseUrl = WEBSITE_URL + '/res/images/megaMenu/';
const images = {
  'Women\'s': `${baseUrl}/womenEyeglasses.avif`,
  'Women\'s Sunglasses': `${baseUrl}/womenSunglasses.avif`,  
  'Women\'s Eyeglasses': `${baseUrl}/womenEyeglasses.avif`,
  'Eyeglasses': `${baseUrl}/unisexEyeglasses.avif`,
  'Women\'s Optical': `${baseUrl}/womenEyeglasses.avif`,
  'Men\'s': `${baseUrl}/menEyeglasses.avif`,
  'Men\'s Sunglasses': `${baseUrl}/menSunglasses.avif`,  
  'Men\'s Eyeglasses': `${baseUrl}/menEyeglasses.avif`,  
  'Men\'s Optical': `${baseUrl}/menEyeglasses.avif`,    
  'Unisex': `${baseUrl}/unisexEyeglasses.avif`,
  'Sunglasses': `${baseUrl}/unisexSunglasses.avif`,
  'Unisex Sunglasses': `${baseUrl}/unisexSunglasses.avif`,  
  'Unisex Optical': `${baseUrl}/unisexEyeglasses.avif`,
};

export const popularCategoriesPlugin: AutocompletePlugin<
  PopularCategoryHit,
  {}
> = {
  getSources() {
    return [
      {
        sourceId: 'popularCategoriesPlugin',
        getItems() {
          return getAlgoliaFacets({
            searchClient,
            queries: [
              {
                indexName: ALGOLIA_PRODUCTS_INDEX_NAME,
                facet: 'list_categories',
                params: {
                  facetQuery: '',
                  maxFacetHits: 6,
                },
              },
            ],
          });
        },
        getItemInputValue({ item }) {
          return item.label;
        },
        onSelect({ setIsOpen }) {
          setIsOpen(true);
        },
        templates: {
          header({ Fragment }) {
            return (
              <Fragment>
                <span className="aa-SourceHeaderTitle">Popular categories</span>
                <div className="aa-SourceHeaderLine" />
              </Fragment>
            );
          },
          item({ item, components }) {
            return <CategoryItem hit={item} components={components} />;
          },
        },
      },
    ];
  },
};

type CategoryItemProps = {
  hit: PopularCategoryHit;
  components: AutocompleteComponents;
};

function CategoryItem({ hit }: CategoryItemProps) {
  const categoryName = hit.label.replace('Optical', 'Eyeglasses');
  return (
    <div className="aa-ItemWrapper aa-PopularCategoryItem">
      <div className="aa-ItemContent">
        <div className="aa-ItemPicture">
          <img src={images[hit.label]} alt={hit.label} />
        </div>
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            {categoryName} <span>({hit.count})</span>
          </div>
        </div>
      </div>
    </div>
  );
}
