/** @jsxRuntime classic */
/** @jsx h */
import {
  AutocompleteComponents,
  AutocompletePlugin,
  getAlgoliaResults,
} from '@algolia/autocomplete-js';
import { SearchResponse } from '@algolia/client-search';
import { h } from 'preact';
import { useState } from 'preact/hooks';

import { Blurhash, StarIcon, FavoriteIcon } from '../components';
import { searchClient } from '../searchClient';
import { ALGOLIA_PRODUCTS_INDEX_NAME, ALGOLIA_ACCESSORIES_INDEX_NAME, ALGOLIA_CONTACTS_INDEX_NAME, ALGOLIA_READERS_INDEX_NAME, WEBSITE_URL } from '../constants';
import { ProductHit } from '../types';
import { cx } from '../utils';

export const productsPlugin: AutocompletePlugin<ProductHit, {}> = {
  getSources({ query }) {
    if (!query) {
      return [];
    }

    return [
      {
        sourceId: 'productsPlugin',
        getItems({ setContext }) {
          return getAlgoliaResults<ProductHit>({
            searchClient,
            queries: [
              {
                indexName: ALGOLIA_PRODUCTS_INDEX_NAME,
                query,
                params: {
                  hitsPerPage: 4,
                },
              },
              {
                indexName: ALGOLIA_ACCESSORIES_INDEX_NAME,
                query,
                params: {
                    hitsPerPage: 4,
                },
              },
              {
                indexName: ALGOLIA_CONTACTS_INDEX_NAME,
                query,
                params: {
                    hitsPerPage: 4,
                },
              },
              {
                indexName: ALGOLIA_READERS_INDEX_NAME,
                query,
                params: {
                    hitsPerPage: 4,
                },
              },
            ],
            transformResponse({ hits, results }) {
              setContext({
                nbProducts: (results[0] as SearchResponse<ProductHit>).nbHits,
              });

              let filteredHits = hits.filter((hit, index) => { if(index < 9) return hit; });

              return filteredHits;
            },
          });
        },
        onSelect({ setIsOpen }) {
          setIsOpen(true);
        },
        templates: {
          header({ state, Fragment }) {
            return (
              <Fragment>
                <div className="aa-SourceHeaderTitle">
                  Products for {state.query}
                </div>
                <div className="aa-SourceHeaderLine" />
              </Fragment>
            );
          },
          item({ item, components }) {
            return <ProductItem hit={item} components={components} />;
          },
          footer({ state }) {
            return (
              Number(state.context.nbProducts) > 8 && (
                <div style={{ textAlign: 'center' }}>
                  <a
                    href={WEBSITE_URL + 'search.aspx?query=' + state.query}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="aa-SeeAllBtn"
                  >
                    See All Products ({state.context.nbProducts})
                  </a>
                </div>
              )
            );
          },
        },
      },
    ];
  },
};

function formatPrice(value: number, currency: string) {
  return value.toLocaleString('en-US', { style: 'currency', currency });
}

type ProductItemProps = {
  hit: ProductHit;
  components: AutocompleteComponents;
};

function ProductItem({ hit, components }: ProductItemProps) {
  const [loaded, setLoaded] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const imgUrl = WEBSITE_URL + hit.image_urls[0];
  const productLink = WEBSITE_URL + hit.slug;
  const variants = hit.variants?.filter((variant, index)=> { if(index < 5) return variant; });    
  
  return (
    
    <a
      href={productLink}
      target="_blank"
      rel="noreferrer noopener"
      className="aa-ItemLink aa-ProductItem"
    >
      <div className="aa-ItemContent">
        <div
          className={cx('aa-ItemPicture', loaded && 'aa-ItemPicture--loaded')}
        >
          <img
            src={ imgUrl }
            alt={hit.name}
            onLoad={() => setLoaded(true)}
          />
        </div>

        <div className="aa-ItemContentBody">
          <div>
            {hit.brand && (
              <div className="aa-ItemContentBrand">
                <components.Highlight hit={hit} attribute="brand" />
              </div>
            )}
            <div className="aa-ItemContentTitleWrapper">
              <div className="aa-ItemContentTitle">
                <components.Highlight hit={hit} attribute="name" />
              </div>
            </div>
          </div>
          <div>
            <div className="aa-ItemContentPrice">
              <div className="aa-ItemContentPriceCurrent">
                {formatPrice(hit.price.value, 'USD')}
              </div>
              {hit.price.on_sales && (
                <div className="aa-ItemContentPriceDiscounted">
                  {formatPrice(hit.price.discounted_value, 'USD')}
                </div>
              )}
            </div>            
          </div>
          <div className="aa-ItemContentColors" style={variants != null ? 'display:block' : 'display:none'}>
            <ul>{variants?.map((variant) => <li>{variant.frame_color} / {variant.lens_color} </li>)}</ul>
          </div>
        </div>

        <button
          className="aa-ItemFavorite"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setFavorite((currentFavorite) => !currentFavorite);
          }}
        >
          <div className="aa-ItemIcon aa-ItemIcon--noBorder aa-FavoriteIcon">
            <FavoriteIcon
              className={cx(!favorite && 'aa-FavoriteIcon--outlined')}
            />
          </div>
        </button>
      </div>
    </a>
  );
}
